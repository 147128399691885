import './index.sass';
import '../../../js/main.js';
$( document ).ready(function() {
  const text = 'Push me 1';
  const satisfaction = `${text}, and then just touch me`;

  (() =>console.log(satisfaction))();
});

$( ".calendar" ).hover(function() {
  if($('.calendar .uk-card').length == 0) {
    $( ".calendar .distinguish" ).each(function( index ) {
      let  id = $(this).data('id');
      $(this).append($( "<div data-uk-drop><div class='uk-card uk-card-body uk-card-default uk-padding-small uk-text-left'></div></div>"));
      $(this).find('.uk-card').load( "https://smolshkolaiskusstw8.ru/ajax1?id="+id+"" );
    });
  }
});


